import React, { ReactElement, FC, useState, useEffect } from "react"
import { Typography, Select, Button } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { navigate } from "gatsby"
import { useCookies } from "react-cookie"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { I_Option, I_CountryLanguage } from "../../global/interfaces"

import "./country-language-banner.less"

const { Option } = Select
const { Text } = Typography

const CountryLanguageBannerComponent: FC<I_CountryLanguage> = ({
  text,
  button,
  countryOptions,
  languageOptions,
  baseUrl,
}: I_CountryLanguage): ReactElement => {
  const [, country, language] = baseUrl.split("/")
  const [cookies, setCookie] = useCookies()
  const [selectedCountry, setSelectedCountry] = useState<undefined | string>(
    country
  )
  const [selectedLanguage, setSelectedLanguage] = useState<undefined | string>(
    language
  )
  const [availableLanguages, setAvailableLanguages] = useState<I_Option[]>([])
  const [isVisible, setIsVisible] = useState(false)

  const handleOnClick = () => {
    if (cookies && cookies.letturalia) {
      const { cookiesConsent } = cookies.letturalia
      if (cookiesConsent) {
        const newCookies = {
          ...cookies.letturalia,
          country: selectedCountry,
          language: selectedLanguage,
        }
        setCookie("letturalia", newCookies, { path: "/", maxAge: 31536000 })
      }
    }
    trackCustomEvent({
      category: "Country and Language Select Button",
      action: "Click",
      label: JSON.stringify({
        country: selectedCountry,
        language: selectedLanguage,
      }),
    })
    navigate(`/${selectedCountry}/${selectedLanguage}`)
  }

  useEffect(() => {
    if (selectedCountry === "es") {
      setAvailableLanguages(
        languageOptions.options.filter(({ value }) => value !== "it")
      )
    } else if (selectedCountry === "it") {
      setAvailableLanguages(
        languageOptions.options.filter(({ value }) => value !== "es")
      )
    }
  }, [selectedCountry])

  useEffect(() => {
    if (!country) {
      if (cookies?.letturalia?.country && cookies?.letturalia?.language) {
        navigate(
          `/${cookies?.letturalia?.country}/${cookies?.letturalia?.language}`
        )
      } else {
        setIsVisible(true)
      }
    } else {
      if (!language) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
  }, [language, country, cookies])

  return (
    <section
      className={
        isVisible
          ? "country-language-banner"
          : "country-language-banner--hidden"
      }
    >
      <div className="country-language-banner__wrapper">
        <Text className="country-language-banner__text">{text}</Text>
        <div className="country-language-banner__inputs">
          <div className="country-language-banner__selects">
            <Select
              placeholder={countryOptions.label}
              className="country-language-banner__select"
              onChange={(value: string) => setSelectedCountry(value)}
              value={selectedCountry}
            >
              {countryOptions.options.map(({ id, text, value }: I_Option) => (
                <Option key={id} value={value}>
                  {text}
                </Option>
              ))}
            </Select>
            <Select
              placeholder={languageOptions.label}
              disabled={!selectedCountry}
              className="country-language-banner__select"
              onChange={(value: string) => setSelectedLanguage(value)}
            >
              {availableLanguages.map(({ id, text, value }: I_Option) => (
                <Option key={id} value={value}>
                  {text}
                </Option>
              ))}
            </Select>
          </div>
          <div className="country-language-banner__buttons">
            <Button
              ghost
              onClick={handleOnClick}
              className="country-language-banner__submit"
              disabled={!selectedCountry || !selectedLanguage}
            >
              {button.text}
            </Button>
            <Button
              type="link"
              shape="circle"
              onClick={() => setIsVisible(false)}
              className="country-language-banner__close"
              icon={<CloseOutlined />}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CountryLanguageBannerComponent
