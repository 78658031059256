import React from "react"
import { Helmet } from "react-helmet"

import {
  getWindowNavigatorLanguage,
  getWindowLocationHref,
  getWindowLocationHost,
} from "../../global/utils/window"
import { I_SEO } from "../../global/interfaces"

const SEOComponent = ({ title, description, openGraph, baseUrl }: I_SEO) => {
  const [lang] = getWindowNavigatorLanguage().split("-")
  const url = getWindowLocationHref()
  const host = getWindowLocationHost()
  const [, countryFromPathname] = baseUrl.split("/")
  const siteUrl = countryFromPathname
    ? `${host}/${countryFromPathname}`
    : countryFromPathname

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={openGraph.type} />
      <meta property="og:title" content={openGraph.title} />
      <meta property="og:description" content={openGraph.description} />
      <meta property="og:image:secure_url" content={openGraph.image.url} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={siteUrl} />
      <meta name="twitter:title" content={openGraph.title} />
      <meta name="twitter:description" content={openGraph.description} />
      <meta name="twitter:image" content={openGraph.image.url} />
      {/* <meta name="twitter:site" content={openGraph.title} />
      <meta name="twitter:creator" content={openGraph.title} /> */}
    </Helmet>
  )
}

export default SEOComponent
