import React, { ReactElement, FC, useState, useEffect } from "react"
import { Drawer, Button } from "antd"
import { useCookies } from "react-cookie"
import ReactMarkdown from "react-markdown"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { I_CookiesBanner } from "../../global/interfaces"

import "./cookies-banner.less"

const CookiesBannerComponent: FC<I_CookiesBanner> = ({
  title,
  text,
  acceptButton,
}: I_CookiesBanner): ReactElement => {
  const [visible, setVisible] = useState(true)
  const [cookies, setCookie] = useCookies()

  const handleOnClick = () => {
    setCookie(
      "letturalia",
      { cookiesConsent: true },
      { path: "/", maxAge: 31536000 }
    )
    trackCustomEvent({
      category: "Cookies Accept Button",
      action: "Click",
    })
    setVisible(false)
  }

  useEffect(() => {
    if (cookies && cookies.letturalia) {
      const { cookiesConsent } = cookies.letturalia
      setVisible(cookiesConsent ? false : true)
    }
  }, [cookies])

  return (
    <Drawer
      title={
        <div className="cookies-banner__header">
          {title}
          <Button
            type="primary"
            onClick={handleOnClick}
            className="cookies-banner__button"
          >
            {acceptButton.text}
          </Button>
        </div>
      }
      placement="bottom"
      closable={false}
      onClose={() => setVisible(false)}
      visible={visible}
      className="cookies-banner"
    >
      <ReactMarkdown children={text} className="cookies-banner__text" />
    </Drawer>
  )
}

export default CookiesBannerComponent
